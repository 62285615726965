import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40')
];

export const server_loads = [0];

export const dictionary = {
		"/": [5],
		"/app": [6],
		"/app/applicant-experience/[appId]": [~23],
		"/app/awards": [~24,[2]],
		"/app/dashboard": [~25,[3]],
		"/app/initial/[jobId]": [~26],
		"/app/profile": [~27],
		"/app/reroute": [~28],
		"/app/[appId]/basicComputer": [~7],
		"/app/[appId]/cognition": [~8],
		"/app/[appId]/complete": [~9],
		"/app/[appId]/culture": [~10],
		"/app/[appId]/email": [~11],
		"/app/[appId]/englishGrammar": [~12],
		"/app/[appId]/finalDetermination": [~13],
		"/app/[appId]/initialDetermination": [~14],
		"/app/[appId]/listening": [~15],
		"/app/[appId]/personality": [~16],
		"/app/[appId]/readingComprehension": [~17],
		"/app/[appId]/remap": [~18],
		"/app/[appId]/situationalJudgement": [~19],
		"/app/[appId]/typingTest": [~20],
		"/app/[appId]/voice": [~21],
		"/app/[appId]/welcome": [~22],
		"/auth/forgot": [29],
		"/auth/login": [30],
		"/auth/token/[token]": [~31],
		"/browserGuides": [32],
		"/faq": [33],
		"/initial/[jobId]": [~34],
		"/invitation/[refId]": [~35],
		"/jobs": [~36],
		"/jobs/[jobId]": [~37],
		"/privacy": [38],
		"/questions": [39,[4]],
		"/questions/message": [40,[4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';